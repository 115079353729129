.privacy-wrapper {
  margin-bottom: 86px;

  ol {
    list-style: auto;
    padding-left: 20px;
  }

  .privacy-block {
    margin-bottom: 23px;

    .privacy-heading {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e3e3f;
      margin-bottom: 24px;
    }

    .privacy-question {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e3e3f;
      margin-bottom: 16px;
    }

    .privacy-text {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #3e3e3f;

      p {
        margin-bottom: 24px;

        a {
          color: #3e3e3f;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
      }

      .list-title {
        margin-bottom: 16px;
      }

      ul {
        li:last-child {
          margin-bottom: 0;
        }
      }

      .wrapper-list {
        li {
          margin-bottom: 10px;
        }

        .bullet-list {
          margin-bottom: 0;
        }

      }

      .privacy-list {
        li {
          margin-bottom: 27px;
        }

      }

      .bullet-list {
        margin-bottom: 16px;
        line-height: 1.7;

        li {
          position: relative;
          padding-left: 16px;
          margin-bottom: 0;
        }

        li:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          top: 10px;
          left: 0;
          background-color: #6a747a;
        }

      }

    }


  }

  .privacy-block:last-child {
    margin-bottom: 0;
  }

}