.footer {
  background-color: #b14619;
  padding: 21px 0;

  .footer-inner {
    display: flex;

    .footer-nav {
      .nav-list {
        display: flex;
        flex-wrap: wrap;

        .list-item {
          margin-right: 60px;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            .item-link {
              color: #f8bd96;
            }
          }

          .item-link {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
    }

    .footer-rights {
      margin-left: auto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
}