.assessment-container {
  .form-group .form-control.is-invalid {
    border: solid 1px #e3e7ea;
    background-color: #f3f6f8;
  }

  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .form-group .error-wrapper {
    display: none;
  }

  &.invalid {
    .form-group .error-wrapper {
      display: block;
    }

    .form-group .form-control.is-invalid {
      border: solid 1px #ff3b30;
      background-color: #feeded;
    }

    .was-validated .form-control:invalid:focus,
    .form-control.is-invalid:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  .tab-content {
    padding-top: 0;

    .tab-pane {
      padding: 0;
    }
  }
}
.image-with-header-tab-content{
  display: flex;
    align-items: center;

}
.assessment-header {
  display: flex;
  align-items: center;
  margin-bottom: 34px;

  .assessment-name {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    display: flex;
    align-items: center;

    .assessment-back {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      outline: none;
      border: none;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .assessment-buttons {
    margin-left: auto;
  }
}

.assessment-complete {
  padding: 10px 29px;
  position: absolute;
  margin-top: 10px;
  top: -5px;
  right: 0;
}

.assessment-block {
  margin-bottom: 34px;

  .block-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    margin-bottom: 24px;
  }

  .block-subtitle {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    margin-bottom: 13px;
  }

  .column {
    align-items: flex-start;
    flex-direction: column;
  }
  .align-items-end{
    align-items: flex-end;

  }

  .block-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px 18px -12px;
   
    .component-container {
      display: flex;
      align-items: center;
    }

    .form-group {
      width: 236px;
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 16px;

      .form-label {
        position: relative;
        padding-right: 0;
        flex: 1;
        display: flex;

        .required-indicator {
          margin: 0 1px 0 0;
          color: #ff3b30;
          width: 7px;
          display: none;
        }
      }

      .label-wrapper {
        position: relative;
        padding-right: 0;
        flex: 1;
        display: flex;

        .required-indicator {
          margin: 0 1px 0 0;
          color: #ff3b30;
          width: 7px;
          display: none;
        }

        .tooltip-trigger {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      .label-wrapper.with-tooltip {
        padding-right: 25px;
      }
    }

    .form-group.calculation {
      width: 102px;

      .form-control {
        padding: 10px 12px;
      }
    }
  }

  .row-small {
    .form-group {
      width: 196px;

      .label-wrapper {
        .tooltip-trigger {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .row-grid {
    .form-group {
      .label-wrapper {
        label {
          color: #3e3e3f;
          font-weight: bold;
          margin-bottom: 12px;
        }
      }
    }
  }

  .save-button_wrapper {
    width: 100%;
    margin-left: 12px;
  }

  .save-btn {
    padding: 10px 27px;
  }
}

// end of assessment

//file upload
.file-upload {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  label {
    padding: 4px 20px;
  }

  input {
    display: none;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 11px;
  }

  .no-file {
    color: #616464;
  }

  .has-file {
    color: #3e3e3f;
  }
}

//file upload finish

//input
.form-group {
  position: relative;

  label {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #616464;
    margin-bottom: 6px;
  }

  .error-wrapper {
    position: absolute;
    bottom: -13px;
    left: 12px;
    color: #ff3b30;
    font-size: 12px;
  }

  .form-control {
    border-radius: 5px;
    border: solid 1px #e3e7ea;
    background-color: #f3f6f8;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    padding: 10px 36px 10px 12px;
    font-family: inherit;

    &.is-invalid {
      border: solid 1px #ff3b30;
      background-color: #feeded;
      background-image: none;
    }

    &:disabled {
      background-color: #fff;
      border: none;
      font-size: 16px;
      font-weight: bold;
      padding-left: 0;
      padding-top: 12px;
    }
  }

  .invalid-feedback {
    margin-top: 6px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff3b30;
  }

  textarea.form-control {
    min-height: 94px;
  }
}

.form-check {
  position: relative;
  padding-left: 12px;
  width: 100%;
  margin-bottom: 16px;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    left: -9999px;
  }

  label {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #616464;
    padding-left: 32px;
    user-select: none;
  }

  input[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 12px;
    top: 1px;
    transform: translateY(0);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

//input end

//tabs
.package-sticky-header-assesments{
  position: sticky;
  top: 158px !important;
  z-index: 100;  
  background-color: #fff;
}
.package-sticky-tabs { 
  position: sticky;
  top: 208px !important;  
  z-index: 100; 
  background-color: #fff;
}

.single-sticky-tabs{ 
  position: sticky;
  top: 158px !important;  
  z-index: 100;  
  background-color: #fff;
}
.nav-tabs {
  border: none;
  overflow-x: auto;
  flex-wrap: nowrap;

  padding-bottom: 12px;
  margin-top: 15px;
  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f3f6f8;
    border-radius: 5px;
    border: solid 1px #e3e7ea;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9ea6ae;
    border-radius: 5px;
  }

  .nav-item {
    text-transform: uppercase;
    flex: 1 0;
    margin-right: 2px;
    padding: 15px 11px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3e3e3f;
    border-bottom: 2px solid #b14619;
    border-top: 1px solid #e1e1e3;
    border-left: 1px solid #e1e1e3;
    border-right: 1px solid #e1e1e3;
    background-color: #fff;
    border-radius: 0;
    margin-bottom: 0;
    white-space: nowrap;

    &.active {
      background-color: #be531c;
      border-bottom: 2px solid rgba(0, 0, 0, 0.3);
      border-top: none;
      border-left: none;
      border-right: none;
      padding: 16px 12px 15px 12px;
      font-weight: normal;
      color: #ffffff;
    }
  }

  .nav-item:last-child {
    margin-right: 0;
  }

  &.primary-tabs {
    margin-bottom: 0;
    padding-bottom: 0;

    .nav-item {
      background-color: #f3f6f8;
      color: #3e3e3f;
      border: none;
      position: relative;
      z-index: 2;
      border-bottom: 2px solid #d0d0d1;

      &.active {
        border-top: 2px solid #be531c;
        font-weight: bold;
        border-right: 2px solid #d0d0d1;
        border-left: 2px solid #d0d0d1;
        border-bottom: none;
      }
    }
  }
}

.tab-content {
  padding-top: 36px;

  .inner-tabs-wrapper {
    border: 2px solid #d0d0d1;
    padding-top: 36px;
    position: relative;
    top: -2px;
    z-index: 1;
    margin-bottom: 36px;

    & > .tab-content {
      padding: 0 24px;
    }
  }
}

//tabs end

//tooltip
.tooltip {
  .arrow:before {
    border-top-color: #838e93;
  }

  .tooltip-inner {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    background-color: #838e93;
    border-radius: 4px;
    max-width: 180px;
  }
}

//tooltip end

//for showing assessment

.hide {
  display: none;
}
