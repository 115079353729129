.chart-wrapper {
  h2 {
    font-family: Arial;
    font-size: 24px;
    font-weight: bold;
  }
  .chart-heading {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    margin-bottom: 24px;
  }

  .chart-heading.heading-large {
    font-size: 24px;
  }

  .chart-heading.heading-small {
    font-size: 18px;
  }

  .chart-description {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #616464;
    margin-bottom: 24px;
  }

  .chart-legend {
    padding: 18px 0 18px 14px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(131, 142, 147, 0.31);
    background-color: #ffffff;

    .legend-list {
      display: flex;
      flex-wrap: wrap;
      .list-item {
        padding: 7px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3e3e3f;
        position: relative;
        display: flex;
        align-items: center;
        span{
          display: block;
          width:12px;
          height: 12px;
          margin-right: 6px;
        }

      }
    }

  }
  .traffic-light-legend {
    padding: 22px 48px 0 24px;
    border-radius: 4px;
    box-shadow: 0 2px 7px 0 rgba(198, 199, 202, 0.56);
    background-color: #ffffff;
    position: relative;

    .legend-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 75%;
      .list-item {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #616464;
        margin-right: 0;
        margin-bottom: 22px;
        padding-left: 34px;
        position: relative;

        &:before {
          content: "";
          width: 18px;
          height: 18px;
          position: absolute;
          top: 2px;
          left: 0;
          background-color: var(--traffic-light-color);
        }


        .list-item-text {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #3e3e3f;
          margin-right: 5px;
          width: auto;
          height: auto;
        }
      }

      .list-item:last-child {
        margin-right: 0;
      }

    }

    .tooltip-trigger {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 24px;
    }

  }
  .drilldown-charts {
    display: flex;
    flex-wrap: wrap;

    .drilldown-chart {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .drilldown-chart:nth-child(odd) {
      padding-right: 12px;
    }

    .drilldown-chart:nth-child(even) {
      padding-left: 12px;
    }


  }

}

.chart-wrapper.general-chart {
  background-color: #fff;
  padding-bottom: 86px;
}

.chart-wrapper.emissions-chart {
  background-color: #f3f6f8;
  padding: 60px 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200vw;
    left: -200vw;
    background-color: #f3f6f8;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 200vw;
    right: -200vw;
    background-color: #f3f6f8;
  }


}

.chart-filter {
  padding: 34px 24px 11px;
  border-radius: 2px;
  box-shadow: 0 2px 7px 0 rgba(198, 199, 202, 0.56);
  background-color: #ffffff;
  margin-top: -36px;
  margin-bottom: 34px;

  .filter-checkboxes {
    padding-top: 15px;

    .checkboxes-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;

      .form-check {
        padding: 0 12px;
        width: 225px;
        margin-bottom: 24px;

        .form-check-label {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #3e3e3f;
          position: relative;

          &:before {
            left: 0;
            top: -4px;
          }

        }

      }

    }


  }

}

.kpi-row.action-row {
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 30px;
  .btn {
    margin-right: 5px;
  }
  .btn:first-child {
    margin-right: 20px;
  }
}
.action-row-btn{
  justify-content: flex-end;
  width: inherit;
  margin-bottom: 30px;
  .btn {
    margin-right: 5px;
  }
  .btn:first-child {
    margin-right: 20px;
  }
  height: 20px;
  padding-left: 20px;
  padding-top: 20px;
}
.empty-msg{
  text-align: center;
  padding-top: 20px;
}
.kpi-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  .kpi-item {
    padding: 0 12px;
    width: 294px;
    margin-bottom: 24px;

    .form-group {
      margin-bottom: 0;

      label {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3e3e3f;
      }

      select {
        width: 100%;
      }
    }

    .comparison-item {
      height: 100%;
      border-radius: 2px;
      box-shadow: 0 2px 7px 0 rgba(198, 199, 202, 0.56);
      background-color: #ffffff;
      padding: 16px 21px 14px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .comparison-title {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #3e3e3f;
        margin-bottom: 3px;
      }

      .comparison-data {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: var(--item-text);
        position: relative;
        .inner-data {
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          border-width: 2px;
          border-style: solid;
          border-radius: 41.5px;
          padding: 3px 7px;
          position: absolute;
          right: -60px;
          top: 53%;
          transform: translateY(-50%);
        }
      }

      .comparison-subtitle {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #616464;

        .tooltip-trigger {
          width: 24px;
          height: 24px;
          margin-left: 2px;
        }
      }
      .comparison-data, .comparison-subtitle {
        display: flex;
        align-items: center;
      }
    }

    .comparison-item.performance-item {
      justify-content: space-between;
      padding: 25px 21px;

      .comparison-data,
      .comparison-subtitle {
        margin-bottom: 0;
      }
    }
  }
}

.kpi-row.kpi-row-sm {
  .kpi-item {
    width: 236px;
  }
}

.kpi-row.kpi-row-md {
  .kpi-item {
    width: 264px;
  }
}

.kpi-tabs {
  margin-top: 86px;
}

.recharts-text {
  font-size: 12px;
  color: #3e3e3f;
}

.tab-pane {
  padding: 0;
}
.dashboard-wrapper{
  position: relative;
  padding: 0;
}
.tooltip-heading {
  font-weight: bold;
  text-align: left;
  display: inline-block;
  background-color: #838e93;
  color: #ffffff;
}

.tooltip-text {
  background-color: #838e93;
  display: inline-block;
  text-align: left;
  color: #ffffff;
}
.popover[data-popper-placement="right"]{
  .arrow {
    &:after,
    &:before {
      border-right-color: #838e93 !important;
    }
  }
}
.popover[data-popper-placement="top"] {
  .arrow {
    &:after,
    &:before {
      border-top-color: #838e93 !important;
    }
  }
}
