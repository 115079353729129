
.contact-content {
  padding: 0 0 86px;

  .contact-us-header {
    height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .contact-title{
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 16px;
    }
    .contact-subtitle{
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  .row {
    align-items: center;
  }
}

.contact-us-form {
  display: flex;
  width: 100%;
  padding-top: 86px;

  .form-col {
    width: 100%;

    .contact-us-heading {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e3e3f;
      margin-bottom: 24px;
    }

    .form-group-select {
      margin-bottom: 24px;

      select {
        width: 100%;
        min-height: 36px;
      }
    }

    .from-group-textarea {
      margin-bottom: 24px;

      .form-control {
        min-height: 170px;
      }
    }

    .contact-us-btn {
      padding: 10px 65px;

      &:disabled {
        border-color: #de9f7c !important;
      }
    }
  }
}
