.login-page {
  min-height: 100vh;
  display: flex;
  background-image: url("../../assets/images/bg_neww.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .welcome-col {
    max-width: 32.7%;
    width: 100%;
    //background-image: url("../../assets/images/bg-login-left.png");
    //background-repeat: no-repeat;
    //background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;


    .welcome-text {
      padding: 0 57px 0 67px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #ffffff;
      @media screen and (max-width: 1200px) {
        padding: 0 40px;
      }

      .welcome-heading {
        font-size: 60px;
        font-weight: bold;
        line-height: 1.17;
        margin-bottom: 32px;
        @media screen and (max-width: 1200px) {
          font-size: 48px;
        }
      }

      .welcome-paragraph {
        font-size: 46px;
        font-weight: normal;
        line-height: normal;
      }
    }
  }

  .sign-up-col {
    flex: 1;
    //background-image: url("../../assets/images/bg-login-right.png");
    //background-repeat: no-repeat;
    //background-size: cover;
    //background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;

    .sign-up-wrapper {
      max-width: 378px;
      width: 100%;
      transform: translateX(-11px);

      .sign-up-logo {
        margin-bottom: 24px;
        user-select: none;
      }

      .sing-up-form {
        border-radius: 8px;
        box-shadow: 0 17px 30px 10px rgba(39, 25, 19, 0.12);
        background-color: #ffffff;
        padding: 24px;
        position: relative;

        .form-heading {
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.11;
          letter-spacing: normal;
          color: #3e3e3f;
          margin-bottom: 20px;
        }

        .form-group {
          margin-bottom: 26px;
          position: relative;

          .forgot-password {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #c35426;
          }
        }

        .form-check {
          margin-bottom: 24px;
        }

        .reset-password-text {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: normal;
          color: #3e3e3f;
          margin-bottom: 16px;
        }

        .close-page {
          position: absolute;
          top: 24px;
          right: 24px;
        }

        .email-sent,
        .password-changed {
          text-align: center;

          .success-img {
            margin-bottom: 24px;
            width: 80px;
            height: 80px;
          }

          .success-text {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            text-align: center;
            color: #616464;
            margin-bottom: 24px;
          }
        }

        .email-sent {
          padding: 10px 0;
        }

        .password-changed {
          padding: 17px 0;
        }

        .btn {
          width: 100%;

          &.get-started {
            font-size: 18px;
            padding: 13px;
          }
        }
      }
    }
  }
}
