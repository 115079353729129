.nav-tabs {
  border: none;
  margin-bottom: 36px;
}

.nav-tabs .nav-item {
  flex: 1;
  margin-right: 2px;
  cursor: pointer;
}

// .nav-tabs .nav-item .nav-link {
//   padding: 14px 5px;
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   text-align: center;
//   color: #3e3e3f;
//   border-bottom: 2px solid #b14619;
//   border-top: 1px solid #e1e1e3;
//   border-left: 1px solid #e1e1e3;
//   border-right: 1px solid #e1e1e3;
//   background-color: #fff;
//   border-radius: 0;
//   margin-bottom: 0;
//   cursor: pointer;
// }

// .nav-tabs .nav-item .nav-link.active {
//   background-color: #be531c;
//   border-bottom: 2px solid rgba(0, 0, 0, 0.3);
//   border-top: none;
//   border-left: none;
//   border-right: none;
//   padding: 15px 6px 14px;
//   font-weight: normal;
//   color: #ffffff;
// }

// .nav-tabs .nav-item:last-child {
//   margin-right: 0;
// }

.tab-content {
  padding-top: 36px;
}
