.table-utilities {
  margin-bottom: 116px;
  margin-top: 27px;
  position: relative;
}

.table-utilities .pagination-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-utilities .pagination-wrapper .pagination {
  margin-bottom: 14px;
}

.table-utilities .pagination-wrapper .pagination li {
  margin-right: 7px;
}

.table-utilities .pagination-wrapper .pagination li a {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #616464;
  background-color: #edf0f1;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.table-utilities .pagination-wrapper .pagination li.previous,
.table-utilities .pagination-wrapper .pagination li.next {
  display: none;
}

.table-utilities .pagination-wrapper .pagination li.active a {
  background-color: #be531c;
  color: #ffffff;
}

.table-utilities .pagination-wrapper .pagination li:last-child {
  margin-right: 0;
}

.table-utilities .pagination-wrapper .pagination-info {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9ea6ae;
}

.table-utilities .pages-show {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.table-utilities .pages-show .show-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3f;
  margin-right: 4px;
}