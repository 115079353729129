.assessments-table {

  .assessment-wrapper {
    width: 12%;
  }

  .description-wrapper {
    width: 32%;
    padding: 15px 0;
    padding-left: 15px !important;
  }

  .status-wrapper {
    padding-left: 15px !important;
  }

  .date-created-wrapper,
  .date-completed-wrapper,
  .status-wrapper,
  .action-wrapper {
    flex: 1;
  }

  .table-body {

    .assessment-wrapper,
    .description-wrapper {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.threedots:after {
  content: "\2807";
  font-size: 25px;
  color: #3e3e3f !important;
}

.asking-for-permission {
  width: 145px;
  height: 18px;
  margin: 0 12px 2px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9ea6ae;
}

.asking-for-permission-active {
  width: 145px;
  height: 18px;
  margin: 0 12px 2px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3f;
  cursor: default;
}

.request-approved,
span.request-approved {
  width: 145px;
  height: 16px;
  margin: 2px 0;
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9ea6ae;

  a {
    text-decoration: underline;
  }
}

.request-rejected {
  width: 145px;
  height: 16px;
  margin: 2px 12px 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff3b30;
}

.update-milestone {
  width: 145px;
  height: 16px;
  margin: 0 0 0 12px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.active {
  color: #37a041;
  cursor: default;
}

.cursor-active {
  cursor: pointer;
}

.disabled {
  cursor: default;
  color: #9ea6ae;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: inherit;
}