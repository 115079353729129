input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.input-with-postfix {
  display: flex;
  align-items: center;
}
.postfix {
  display: inline-block;
  margin-left: 5px;
  color: grey;
}
