.custom-toast {
  z-index: 5;
  right: 17px;
  bottom: 50px;
  position: absolute;
  max-width: 330px;
  width: 100%;

  .toast {
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 7px 0 rgba(198, 199, 202, 0.56);
    background-color: #ffffff;

    .toast-body {
      padding: 10px 16px;

      .toast-inner {
        display: flex;
        align-items: center;

        .toast-icon-wrapper {
          .icon-circle {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 12px;
              height: 12px;
            }

          }

          .green-circle {
            background-color: #37a041;
          }
        }

        .toast-content {
          padding: 0 12px 0 24px;

          .content-title {
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3e3e3f;
          }

          .content-text {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3e3e3f;
          }
        }

        .toast-btn-wrapper {
          margin-left: auto;

          .toast-btn {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;

            img {
              width: 16px;
              height: 16px;
            }

          }
        }

      }
    }
  }
}

.homepage-toast {
  max-width: 684px;
  width: 500px;
  margin-bottom: 10px;
  .toast {
    border-radius: 5px;
    border: none;
    box-shadow: 0 2px 7px 0 rgba(198, 199, 202, 0.56);
    background-color: #ffffff;
    max-width: 684px;

    .toast-body {
      padding: 6px 16px;

      .toast-inner {
        display: flex;
        align-items: center;

        .toast-icon-wrapper {
          .icon-circle {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 16px;
              height: 16px;
            }

          }

          .green-circle {
            background-color: #37a041;
          }
        }

        .toast-content {
          padding: 0 16px;

          .content-text {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3e3e3f;

            strong {
              font-weight: bold;
            }

          }
        }

        .toast-btn-wrapper {
          margin-left: auto;

          .toast-btn {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;

            img {
              width: 16px;
              height: 16px;
            }

          }
        }

      }
    }
  }
}
