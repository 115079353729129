.settings-wrapper {
  margin-bottom: 86px;
  .settings-title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    margin-bottom: 6px;
  }

  .settings-subtitle {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c35426;
    margin-bottom: 21px;
  }

  .settings-fields {
    margin-bottom: 12px;

    .fields-row {
      margin: 0 -12px;
      display: flex;
      flex-wrap: wrap;
      .form-section{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        &__title {
          width: 100%;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #3e3e3f;
          padding: 0 12px;
          margin-bottom: 16px;
        }
        .col-6 {
          .form-group{
            width: 50%;
          }
        }
        .form-group {
          width: 25%;
          padding: 0 12px;
          margin-bottom: 20px;
          &.full-width {
            width: 100%;
          }
          .info-label {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #616464;
            margin-bottom: 6px;
          }
          .info-text{
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3e3e3f;
          }
          .static-field {
            padding: 10px 12px 10px 0;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3e3e3f;
          }
        }
      }
    }

  }

  .buttons-wrapper {
    display: flex;

    .btn {
      margin-right: 16px;
      padding: 10px 35px;
    }

    .btn.cancel-btn {
      padding: 10px 25px;
    }

    .btn:last-child {
      margin-right: 0;
    }

  }


}
