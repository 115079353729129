.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

  &__circle {
    height: 48px;
    width: 48px;
    color: #5a5a5a;
    border: 4px solid #be531c;
    display: inline-block;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate-preloader 1s linear infinite;

    @keyframes rotate-preloader {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}