.simple-select {
  & > div:first-child,
  & > span:first-child + div {
    background: none;
    border:solid 1px #e3e7ea;
    border-radius: 5px;
    min-height: auto;
    outline: none;
    box-shadow: none;
    font-size: 14px;
  }
}
