.main-heading {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3f;
}

.main-info {
  .main-text {
    display: flex;
    justify-content: center;

    .main-paragraph {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #3e3e3f;
      margin-bottom: 27px;
      max-width: 48%;
      margin: 5px
    }

    .main-paragraph:last-child {
      margin-bottom: 0;
    }

  }

}
.question-mark-icon{
  width: 100px;
  height: 100px;
}
.about-section {
  padding-bottom: 86px;

  .main-heading {
    margin-bottom: 25px;
  }


  .main-info {
    padding-left: 8px;
  }

}


.about-img {
  width: 99% !important;
}

.padding-header-main-page {
  padding-top: 10px !important;
}

.container-home-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.talk-to-us{
  display: flex;
  flex-direction: row !important;
  width: 85%;
}
.margin-talk-to-us{
  margin-left: 10px;
}
.container-home-page-text-about {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 60%;
}

.welcome-text{
  position: absolute;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  text-align: center;
  margin-bottom: 16px;
}
.container-home-page-wrapper {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}


.blog-section {
  background-color: #f3f6f8;
  padding: 60px 0;

  .main-heading {
    text-align: center;
    margin-bottom: 30px;
  }

  .blog-posts {

    .single-post {
      flex: 1;
      margin-bottom: 39px;
      display: flex;
      flex-direction: column;

      .post-img {
        max-width: 100%;
        width: 100%;
        height: 217px;
        margin-bottom: 24px;
        object-fit: cover;
      }

      .post-heading {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3e3e3f;
        margin-bottom: 16px;
      }

      .post-description {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: #3e3e3f;
        margin-bottom: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        /* number of lines to show */
        -webkit-box-orient: vertical;
      }

      .post-link {
        text-decoration: none;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #c35426;
        display: flex;
        align-items: center;

        img {
          margin-left: 4px;
        }

      }


    }
  }

  .discover-wrapper {
    display: flex;
    justify-content: center;

    .discover-btn {
      padding: 10px 25px;
    }

  }

}

.highlights-section {
  background-color: #ffffff;
  padding: 87px 0;

  .highlights-video {
    position: relative;
    padding-bottom: 57.64%;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  .main-info {
    max-width: 90%;
    margin-bottom: 34px;

    .main-heading {
      margin-bottom: 25px;
    }
  }

  .highlights-link {
    padding: 10px 26px;
  }


}

.social-icons-box{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.social-icons{
  background-color: #be531c;
}
.social-icons-single{
  margin: 20px;
}
.features-wrapper{
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;

}
.features-block{
  max-width: 300px;
  margin: 50px;
}
.feature-image{
  max-width: 300px;
  box-shadow: 8px 8px 15px 12px #0000002b;
}
.features-header{
  color: #be531c;
  text-align: center;
  margin: 15px;
  font-weight: 600;
  font-size: 18px;
}
.features-p{
  font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #3e3e3f;
      margin-bottom: 27px;
      margin: 5px
}
.sessions-section {
  background-color: #be531c;
  padding: 60px 0 30px 0;

  .main-heading {
    text-align: center;
    color: #ffffff;
    margin-bottom: 34px;
  }

  .sessions-posts {
    .single-session {
      flex: 1;
      margin-bottom: 30px;

      .session-img {
        max-width: 100%;
        width: 100%;
        margin-bottom: 16px;
        height: 217px;
        object-fit: cover;
      }

      .session-heading {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin-bottom: 8px;
      }

      .session-date {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #f8bd96;
      }

    }

  }
}

@media (max-width: 820px) {
  .main-info .main-text .main-paragraph {
    max-width: 100%;
  }
}
