@import "~bootstrap/scss/bootstrap";

//@font-face {
//  font-family: 'Arial';
//  src: url('./assets/fonts/ArialRegular.ttf') format('truetype');
//  font-style: normal;
//  font-weight: 400;
//}
//
//@font-face {
//  font-family: 'Arial';
//  src: url('./assets/fonts/ArialItalic.ttf') format('truetype');
//  font-style: italic;
//  font-weight: 400;
//}
//
//@font-face {
//  font-family: 'Arial';
//  src: url('./assets/fonts/ArialBold.ttf') format('truetype');
//  font-style: normal;
//  font-weight: 700;
//}
//
//@font-face {
//  font-family: 'Arial';
//  src: url('./assets/fonts/ArialBoldItalic.ttf') format('truetype');
//  font-style: italic;
//  font-weight: 700;
//}
@font-face {
  font-family: 'IBMPlexSans';
  src: url('./assets/fonts/IBMPlexSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: url('./assets/fonts/IBMPlexSans-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: url('./assets/fonts/IBMPlexSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: url('./assets/fonts/IBMPlexSans-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: 'IBMPlexSans', sans-serif;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

q {

  &:before,
  &:after {
    content: "";
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: unset;

  &:hover {
    text-decoration: none;
  }
}

.btn {
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 10px 12px;

  &:hover {
    color: #ffffff;
  }
}

.action-primary {
  background-color: #be521c !important;
  border-color: #be521c !important;
  box-shadow: none;

  &:hover,
  &:focus,
  &:active {
    background-color: #be521c;
    border-color: #be521c;
    box-shadow: none !important;
  }

  &:disabled {
    background-color: #de9f7c !important;
    pointer-events: none;
  }
}

.action-primary.disabled {
  pointer-events: none;
}

.action-secondary {
  background-color: #838e93;

  &:disabled {
    background-color: #dadee2;
  }
}

[type="checkbox"]:not(:checked)+label:before {
  background-image: url("../src/assets/images/ic-checkbox-empty.svg");
}

[type="checkbox"]:checked+label:before {
  background-image: url("../src/assets/images/ic-checkbox-filled.svg");
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #616464;
}

.show-password {
  position: absolute;
  top: 32px;
  right: 10px;
  cursor: pointer;
}

.container-custom {
  max-width: 1186px;

  .row-custom {
    margin: 0 -42px;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: 1299px) {
      margin: 0 -15px;
    }

    .col-custom {
      width: 100%;
      max-width: 33.33%;
      padding: 0 42px;
      display: flex;

      @media screen and (max-width: 1299px) {
        padding: 0 15px;
      }
    }
  }
}

.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 8px;
      box-shadow: 0 17px 30px 10px rgba(39, 25, 19, 0.12);
      background-color: #ffffff;

      .modal-header {
        border-bottom: none;
        padding: 24px;

        .modal-title {
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.11;
          letter-spacing: normal;
          color: #3e3e3f;
        }

        .modal-subtitle {
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #c35426;
        }

      }

      .modal-body {
        padding: 0 24px;
      }

      .modal-footer {
        padding: 24px;
        border-top: none;

        &>* {
          margin: 0;
        }

        .btn {
          width: 100%;
        }

      }

    }
  }

  .modal-large {
    max-width: 684px;
  }

  .modal-small {
    max-width: 448px;
  }

  .review-modal {
    .modal-content {

      .modal-header {
        padding: 29px 24px 24px 24px;
      }

      .modal-body {
        padding: 0 12px;
      }

      .modal-footer {
        padding: 0 12px 24px 12px;
      }

      .container-fluid {
        padding: 0 12px;

        .row {
          margin-left: -12px;
          margin-right: -12px;

          .col-6 {
            padding: 0 12px;

            .form-group {
              margin-bottom: 24px;
            }

          }
        }

        .row:last-child {
          .col-6 {
            .form-group {
              margin-bottom: 34px;
            }
          }
        }

      }


    }
  }

  .request-sent-modal {
    .modal-content {

      .modal-header {
        justify-content: center;
        padding: 34px 24px 31px 24px;

        .modal-title {
          text-align: center;
        }

      }

      .modal-body {

        .success-info {
          text-align: center;
          margin-bottom: 10px;

          .success-img {
            width: 99px;
            height: 99px;
            margin-bottom: 24px;
          }

          .success-message {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            text-align: center;
            color: #3e3e3f;
            padding: 0 105px;
          }

        }


      }
    }
  }

  .request-sent-modal.textless {
    .modal-content {
      .modal-header {
        padding: 24px 24px 34px;
      }

      .modal-body {
        .success-info {
          margin-bottom: 0;

          .success-img {
            margin-bottom: 0;
          }

        }
      }

    }
  }

  &.accept-agreement-modal {
    .modal-dialog {
      min-width: 684px;
    }

    .modal-content {
      .modal-header {
        padding: 23px 24px 25px 24px;
        flex-direction: column;

        .modal-title {
          text-align: center;
        }
      }

      .modal-body {
        max-height: 310px;
        overflow-y: auto;

        .agreement-text {
          p {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            color: #3e3e3f;
            margin-bottom: 26px;
          }

          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .modal-footer {
        padding: 32px 24px 24px;

        .buttons-wrapper {
          .btn:first-child {
            margin-bottom: 16px;
          }
        }
      }

    }
  }

  .data-protection-modal {
    .modal-content {
      .modal-header {
        padding: 23px 24px 26px 24px;

        .modal-title {
          margin-bottom: 13px;
        }
      }

      .modal-footer {
        padding: 34px 12px 24px 12px;
      }

    }
  }

  &.save-changes-modal {
    .modal-content {
      .modal-header {
        .modal-title {
          text-align: center;
        }
      }

      .modal-footer {
        padding: 10px 8px 24px;

        .container-fluid {
          .row {
            margin: 0 -10px;

            .col-6 {
              padding: 0 10px;
            }
          }
        }

      }

    }
  }

}

.modal-backdrop.show {
  opacity: .7;
}

.kpi-tabs+.tab-content {
  padding-top: 0;
}

#DASHBOARD {
  position: relative;
  margin-bottom: 45px;

  nav {
    z-index: 1;
    background-color: #be531c;
    min-width: 48px;
  }

  // .sidenav---expanded---1KdUL + main {
  //   margin-left: 100px;
  // }
  main {
    margin-left: 60px;
    //transition: all 0.3s ease;
  }

  .sidenav---sidenav-toggle---1KRjR {
    width: 48px;
    height: 48px;
  }

  .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
    width: 48px;
  }

  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    min-width: 240px;
  }

  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo+.sidenav---navtext---1AE_f {
    position: relative;
    left: -10px;
    color: #fff !important;
  }

  .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
  .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
    color: #fff !important;
    opacity: 1;
  }

  .sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo+.sidenav---navtext---1AE_f {
    transition: none;
  }
}

#registrationDate {
  height: 16px;
}

.simple-select>span:first-child+div {
  line-height: 16px;
}

.ifPrefixText {
  width: 200px;
  color: black;
  font-size: 12px;
  padding-right: 10px;
  font-weight: 900;

}