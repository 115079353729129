.table-select {
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
  background-color: #f3f6f8;
  margin-right: 2px;
  z-index: 20;

  &__input-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    background: transparent;
    border: 0;
    text-align: left;
  }

  &__input {
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    width: 80%;
  }

  &__filter-img {
    cursor: pointer;
    display: block;
    position :relative;
    &.active{
      transform: scale(-1);
    }
  }
}
