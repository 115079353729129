.option-container {
  padding: 10px 12px;
  width: 100%;
  cursor: pointer;

  .option-label {
    font-family: IBMPlexSans, sans-serif;
    color: #616464;
    font-size: 14px;

    &.active {
      color: #c35426;
    }
  }
}
