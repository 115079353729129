.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 97px;

  .main-img {
    margin-bottom: 15px;
  }

  .text-img {
    margin-bottom: 33px;
  }

  .main-img,
  .text-img {
    max-width: 100%;
  }

  .return-btn {
    padding: 10px 56px;
  }

}