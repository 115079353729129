.processor-suppliers-table .farm-wrapper {
  width: 14%;
}

.processor-suppliers-table.is-processor .farm-wrapper {
  width: 12%;
}

.processor-suppliers-table .species-wrapper {
  width: 8%;
  padding: 12px !important;
}

.processor-suppliers-table.is-processor .species-wrapper {
  width: 7%;
  padding: 12px !important;
}

.processor-suppliers-table .member-number-wrapper {
  width: 9%;
  padding: 12px !important;
}

.processor-suppliers-table .farmer-wrapper,
.processor-suppliers-table .project-wrapper {
  width: 13%;
}

.processor-suppliers-table.is-processor .farmer-wrapper,
.processor-suppliers-table.is-processor .project-wrapper {
  width: 10%;
}

.processor-suppliers-table .system-type-wrapper {
  width: 12%;
}

.processor-suppliers-table.is-processor .system-type-wrapper {
  width: 11.5%;
}

.processor-suppliers-table.is-processor .processor-wrapper {
  width: 11%;
}

.processor-suppliers-table .location-wrapper {
  width: 11.25%;
}

.processor-suppliers-table.is-processor .location-wrapper {
  width: 10%;
}

.processor-suppliers-table .last-assessment-wrapper {
  width: 12.5%;
}

.processor-suppliers-table.is-processor .last-assessment-wrapper {
  width: 11%;
}

.processor-suppliers-table .index-wrapper {
  width: 7.25%;
  justify-content: center;
}

.filter-utilities {
  display: flex;
  margin-bottom: 32px;
}

.filter-utilities .form-group {
  margin-right: 24px;
  margin-bottom: 0;
}

.filter-utilities .form-group .form-control {
  min-height: 38px;
}

.filter-utilities .utilities-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.filter-utilities .select-group {
  width: 212px;
}

.filter-utilities .select-group label {
  font-weight: bold;
  color: #3e3e3f;
}

.filter-utilities .select-group select {
  min-height: 38px;
}

.filter-utilities .search-group {
  flex: 1;
}

.filter-utilities .search-group .input-inner {
  position: relative;
}

.filter-utilities .search-group .input-inner .form-control {
  font-size: 16px;
  line-height: 1.63;
  color: #3e3e3f;
  padding: 10px 36px 10px 44px;
  background-image: url("../../assets/icons/ic-search.svg");
  background-repeat: no-repeat;
  background-position: 11px 46%;
}
.button-group-supplier {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-end;
}
.btn-search{
  margin-right: 10px;
}

.filter-utilities .search-group .input-inner .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #616464;
}

.filter-utilities .search-group .input-inner .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #616464;
}

.filter-utilities .search-group .input-inner .cross-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-utilities .search-group .input-inner .cross-icon img {
  width: 16px;
  height: 16px;
}

.filter-utilities .btn-wrapper .search-btn {
  padding: 10px 22px;
}
.member-number-wrapper {
  overflow:hidden;
  span {
    word-break: normal;
  }
}
