.imageUpload{
  color:black;
  font-size: 14px;
}

.image-upload-container{
  width: 231px !important;
  text-align: center;
  color: black;
  padding-bottom: 5px;
  margin:3px;
}
.image-upload-container .image-container {
  position: relative;
  display: inline-block; 
}
.upload-button{
  background-color: white !important;
  padding: 0px !important;
  border: none !important;

  
}