.create-assessment-wrapper{
  margin-top: 86px;
  margin-bottom: 164px;
}
.info-col{
  border-left: 2px solid #f2f2f2;
  height: 180px;
}
.select-group-wrapper {
  label {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
  }
}
.checkboxes-wrapper{
  width: 15%;
}
.assessment-name-wrapper{
  width: 85%;
  padding-left: 10px !important;
;
}
.col-item{
  padding: 0 40px;
}
.template-name-wrapper{
  width: 100% !important;
  padding-left: 10px !important;
}
.select-tooltip{
  .tooltip-wrapper {
    position: relative;
    left: 284px;
    top: 20px;
  }
}
.row-checkbox{
  margin-left: -10px;
}
.description-title{
  width: 330px;
  height: 42px;
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3f;
}
.description-text{
  width: 330px;
  height: 438px;
  margin: 16px 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #3e3e3f;
  margin-bottom: 164px;
}
