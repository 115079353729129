  .header {
    position: sticky;
    top: 0;
    z-index: 100;

    .header-top {
      background-color: #fff;
      padding: 16px 0;

      .header-top-inner {
        display: flex;
        align-items: center;

        .header-logo {
          max-width: 273px;
        }

        .header-logout {
          margin-left: auto;
          padding: 11px 17px;
          display: flex;
          align-items: center;

          img {
            margin-left: 9px;
          }
        }
      }
    }

    .header-nav {
      background-image: url("../../../assets/images/bg-nav.png");
      background-size: cover;
      background-repeat: no-repeat;
      padding: 21px 0;

      .nav-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;

        .notifications-wrapper {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 62px;
          left: 50%;
          transform: translateX(-50%);
        }

        .list-item {
          margin-right: 50px;
          position: relative;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            .item-link {
              color: #f8bd96;
            }
          }

          .item-link {
            text-transform: uppercase;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            text-decoration: none;
            position: relative;

            span {
              pointer-events: none;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -32px;
              font-size: 14px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              color: #3e3e3f;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .page-content {
    padding: 86px 0 0;
    flex: 1;
    background-color: #fff;
  }

  //.nav-menu{
  //  display: flex;
  //  justify-content: space-around;
  //}
  .mySelect,
  .mySelect:focus,
  .mySelect:active,
  .mySelect:hover {
    background: white !important;
    border: none !important;
    outline: 0;
  }