.calculated-field{
  display:flex;
  align-items: center;
}
.calculated-field-grid{
  display:flex;
  flex-direction: row;
  width: 236px !important;
  align-items: flex-start;
  // align-items: center;
}
.calculate-button{ 
  margin-left: 5px;
  margin-right: 5px;
}