.from-group-textarea {
  width: 100%;
  .label-wrapper {
    position: relative;
    padding-right: 0;
    flex: 1;
    display: flex;
    .required-indicator {
      margin: 0 1px 0 0 ;
      color: #ff3b30;
      width: 7px;
    }
  }
}
