.empty-table {
  text-align: center;
  padding-top: 89px;
}

.empty-table .empty-img {
  margin-bottom: 16px;
}

.empty-table .empty-message {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9ea6ae;
}