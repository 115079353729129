.grid-wrapper {
  width: 100%;

  .grid-heading {
    display: flex;
    padding: 0 12px;
    align-items: center;

    .grid-title {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3e3e3f;
      margin-right: 20px;
    }

    button {
      background-color: #be521c;
      color: #ffffff;
      border: 0;
      margin: 0 10px;
      width: 25px;
      height: 25px;
      line-height: 1;
      text-align: center;
      padding: 0;

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }

  hr {
    margin: 12px;
  }

// .field-fit{
//   width: 236px;
// }

.checkbox-fit{
  width: 236px;
}
.checkbox-fit:first-child{
  margin-left: 45px;
}
  .fields-row {
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  
  .scroll::-webkit-scrollbar {
    height: 10px;
  }
  .scroll::-webkit-scrollbar-track {
    background-color: #f3f6f8;
    border-radius: 5px;
    border: solid 1px #e3e7ea;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #9ea6ae;
    border-radius: 5px;
  }
}

.table-th-column{

    display: flex;
    align-items: center;
    flex-direction: column;

}
.table-column{

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

}
.table-col{
  display: flex;
  align-items: center;
}

.table-header-column{
  height: 57px !important;
  width: 236px !important;
  text-align: center;
  color: black;
  margin: 0;
  padding-top: 15px;
}
.table-header-row{
  width: 236px !important;
  text-align: center;
  color: black;
  padding-bottom: 5px,
}
.header-fit{
  min-width: 236px;
}
.header-with-tooltip{
  display: flex;
  justify-content: center;
  align-items: center;

}
.header-with-tooltip-text{
  margin-right: 5px !important; 
}
.table-heading-img{
  margin: 3px;
}

.used-for-space{
  width: 236px!important;
  height: 0px !important;
  opacity: 0!important;

}
.fixed-text-item{
  font-size: 12px;
  color: black;
  width: 50%;
}
.fit-fixed-text{
  display: flex;
  max-width: 238px;
  align-items: center;
}