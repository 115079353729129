.table-select {
  display: flex;
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
  background-color: #f3f6f8;
  margin-right: 2px;
  z-index: 20;

  &__input-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
  }

  &__input {
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e3e3f;
    width: 80%;
  }

  &__filter-img {
    cursor: pointer;
    display: block;
  }

  &__menu {
    position: absolute;
    top: 33px;
    left: 0;
    box-shadow: 0 2px 7px 0 rgba(198, 199, 202, 0.56);
    background-color: #ffffff;
    width: calc(100% + 2px);
    max-height: 200px;
    overflow-y: scroll;
    &-item {
      padding: 20px 15px;

      p {
        font-size: 14px;
        color: #616464;
        font-weight: normal;
        cursor: pointer;

        &:hover {
          color: #c35426;
        }
      }

      &-active {
        p {
          color: #c35426;
        }
      }
    }
  }
}
