.retailer-farmer-container {
  padding-top: 10px;
}
.retailer-farmer-actions-row{
  margin-bottom: 58px;
}
.back-btn-wrapper{
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #3e3e3f;
display: flex;
align-items: center;
}
.back-btn {
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: none;

  img {
    width: 16px;
    height: 16px;
  }
}

