.table-heading {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3f;
  margin: 34px 0;
}

.custom-table {
  padding-bottom: 16px;
}

.custom-table .table-header,
.custom-table .table-body .table-item {
  display: flex;
}

.custom-table .table-header .action-wrapper,
.custom-table .table-body .table-item .action-wrapper {
  display: flex;
  justify-content: center;
}

.custom-table .table-header>div {
  display: flex;
  align-items: center;
  background-color: #f3f6f8;
  padding: 15px 0;
  margin-right: 2px;
  border-bottom: 2px solid #be531c;
}

.custom-table .table-header>div span {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3f;
  margin-right: 5px;
}

.custom-table .table-header>div .filter-img {
  margin-left: auto;
  width: 12px;
  height: 12px;
}

.custom-table .table-header>div:last-child {
  margin-right: 0;
}

.custom-table .table-header .action-wrapper {
  display: flex;
  justify-content: center;
}

.custom-table .table-body .table-item {
  border-bottom: solid 1px #e1e1e3;
  border-left: solid 1px #e1e1e3;
  border-right: solid 1px #e1e1e3;
}

.custom-table .table-body .table-item .action-wrapper {
  padding: 9px 12px;
}

.custom-table .table-body .table-item .action-wrapper .table-action-btn {
  max-width: 100px;
  width: 100%;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.custom-table .table-body .table-item:nth-child(odd) {
  background-color: #fff;
}

.custom-table .table-body .table-item:nth-child(even) {
  background-color: #f3f6f8;
}

.custom-table .table-body .table-item>div {
  padding: 12px;
  display: flex;
  align-items: center;
}

.custom-table .table-body .table-item>div span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3e3e3f;
  white-space: normal;

  &.btn {
    color: #ffffff;
  }
}

.custom-table .table-body .empty-table {
  text-align: center;
  padding-top: 89px;
}

.custom-table .table-body .empty-table .empty-img {
  margin-bottom: 16px;
}

.custom-table .table-body .empty-table .empty-message {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9ea6ae;
}


.documents-table .action-wrapper {
  width: 14%;
}

.assessment-header {
  display: flex;
  align-items: center;
  margin-bottom: 34px;
}


.documents-table .name-wrapper,
.documents-table .date-created-wrapper,
.documents-table .type-wrapper {
  flex: 1;
}

.retailer-documents-table {
  .name-wrapper {
    width: 16%;
    word-break: break-all;

    &>span {
      padding: 0 12px;
    }
  }

  .member-number-wrapper {
    width: 12.5%;
    overflow: hidden;
    word-wrap: break-word;
  }

  .farmer-name-wrapper,
  .farm-name-wrapper {
    width: 14%;
  }

  .date-created-wrapper {
    width: 13.5%;
  }

  .type-wrapper {
    width: 16%;
  }

  .action-wrapper {
    width: 14%;
  }

}